/* eslint-disable @typescript-eslint/no-namespace */
import { type Router } from '@angular/router';
export namespace NavigationHelper {
  export function navigateTo(router: Router, url: string): void {
    const urlTree = router.createUrlTree([url]);
    void router.navigateByUrl(urlTree);
  }
  /**
   * Navigates to a specified URL with optional parameters and refreshes the page.
   * @param router - The Angular router service.
   * @param url - The base URL to navigate to.
   * @param params - An array of parameters to append to the URL (default is an empty array).
   */
  export function navigateAndRefresh(router: Router, url: string, params: string[] = []): void {
    // Construct the URL with parameters
    const completeUrl = [url, ...params].join('/');
    // Navigate to a temporary empty route (or another placeholder route) and then return
    router.navigateByUrl('/dummy-route').then(() => {
      // Then, navigate to the actual URL
      router.navigateByUrl(completeUrl);
    });
  }
}
