<div class="header-wrapper" [class.expanded]="expanded">
  <div [ngClass]="isHomePage() ? 'header-title-homepage' : 'header-title'" class="header-title">@if (!pageTitle) { LOGILAB } @else { {{ pageTitle }} }</div>
  @if (!isHomePage()) {
  <div class="header-container">
    <div class="header-content">
      <div class="header-left">
        <div class="header-actions">
          <a class="button-basic-info mx-2" background="none" (click)="onNavigateAction()">
            <app-icon class="fa-xl" icon="left-long" package="solid"></app-icon>
          </a>
        </div>
        <div class="separator-vr"></div>
      </div>

      <div class="header-right mx-1">
        <div class="header-template-left">
          <ng-content select="[HeaderLeft]"></ng-content>
        </div>
        <div class="header-template-right">
          <ng-content select="[HeaderRight]"></ng-content>
        </div>
        <ng-container *ngIf="ExpandedBody.childNodes.length != 0">
          <div class="separator-vr"></div>
          <div class="button-basic-info ms-3 d-flex align-items-center" background="none" (click)="toggleExpand()">
            <span class="body-label me-2">
              @if (expanded) {
              {{ 'GL.ACTIONS.HideFilters' | translate }}
              } @else {
              {{ 'GL.ACTIONS.ShowFilters' | translate }}
              }
            </span>
            <app-icon class="fa-xl" [icon]="expanded ? 'chevrons-up' : 'chevrons-down'" package="regular"></app-icon>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="extra-content" #ExpandedBody [class.hidden]="!expanded">
      <ng-content select="[HeaderBody]"></ng-content>
    </div>
  </div>
  }
</div>
