import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { IconComponent } from "../../../../shared/ui-components/icon/icon.component";
import { Router } from '@angular/router';
import { NavigationHelper } from '@app/shared/utils/nav/nav-helper';
import { Subscription } from 'rxjs';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { SubSink } from 'subsink';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-header-toolbar',
  standalone: true,
  imports: [CommonModule, IconComponent, TranslateModule],
  templateUrl: './header-toolbar.component.html',
  styleUrl: './header-toolbar.component.scss',
})
export class HeaderToolbarComponent implements OnInit, OnDestroy {
  private readonly router: Router = inject(Router);
  private readonly navigationService = inject(NavigationService);
  private readonly subs = new SubSink();

  @Input() pageTitle?: string;
  @Input() urlPath?: string;
  @Input() expanded: boolean = false;

  protected headerSubscritions$ = new Subscription();

  public async ngOnInit (): Promise<void> {
    // console.log('JDEBUG : HeaderComponent ngOnInit',this.navigationService.getUserRoles());

    //need Observable
    this.headerSubscritions$.add(this.navigationService.getPageNameObs().subscribe(pageName => {
      this.pageTitle = pageName;
    }));
  }

  ngOnDestroy (): void {
    this.headerSubscritions$.unsubscribe();
    this.subs.unsubscribe();
  }

  onNavigateAction() {
    NavigationHelper.navigateTo(this.router, this.urlPath ?? '/');
  }

  toggleExpand (): void {
    this.expanded = !this.expanded;
  }

  isHomePage(): boolean {
    return this.router.url === '/';
  }
}
